var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('loading',{attrs:{"active":_vm.loading},on:{"update:active":function($event){_vm.loading=$event}}}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('span',{staticStyle:{"font-family":"'IBM Plex Sans Thai'","font-style":"normal","font-weight":"600","font-size":"24px","line-height":"40px"}},[_vm._v(" ทุนการศึกษา (ป.โท) ")])]),_c('v-spacer'),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-btn',{staticStyle:{"height":"48px"},attrs:{"color":"#2AB3A3","dark":""},on:{"click":function($event){return _vm.goToCreate()}}},[_vm._v("+ เพิ่มทุนการศึกษาปริญญาโท")])],1)],1),_c('v-card',{staticClass:"pa-3 mt-4",attrs:{"color":"#F5F5F5","flat":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-col',{style:(_vm.e1 == 0
              ? 'border-bottom: 3px solid #2ab3a3'
              : 'border-bottom: 1px solid #2ab3a3'),attrs:{"cols":"4","md":"3","sm":"3"}},[_c('v-tab',{staticClass:"textResponsive"},[_c('span',[_c('b',[_vm._v("ภาคปกติ")])])])],1),_c('v-col',{style:(_vm.e1 == 1
              ? 'border-bottom: 3px solid #2ab3a3'
              : 'border-bottom: 1px solid #2ab3a3'),attrs:{"cols":"4","md":"3","sm":"3"}},[_c('v-tab',{staticClass:"textResponsive"},[_c('span',[_c('b',[_vm._v("ภาคพิเศษ")])])])],1),_c('v-col',{staticStyle:{"border-bottom":"1px solid #2ab3a3"},attrs:{"cols":"4","md":"6","sm":"6"}},[_c('v-tab',{staticClass:"text-h6"})],1),_c('v-tabs-slider',{attrs:{"color":"#2AB3A3"}}),_c('v-tab-item',[_c('v-card',{staticClass:"pa-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-text-field',{attrs:{"hide-details":"","prepend-inner-icon":"mdi-magnify","placeholder":"ค้นหาประกาศสมัครเรียน","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-select',{attrs:{"outlined":"","dense":"","items":[
                    'สถานะทั้งหมด',
                    'เปิดใช้งาน',
                    'ปิดใช้งาน',
                    'ฉบับร่าง' ]},on:{"change":function($event){return _vm.searchSelectStatus(_vm.statusSearch)}},model:{value:(_vm.statusSearch),callback:function ($$v) {_vm.statusSearch=$$v},expression:"statusSearch"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersBrandTh,"items":_vm.items1,"search":_vm.search,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status == 'เปิดใช้งาน'
                          ? '#ECFDF3'
                          : item.status == 'ฉบับร่าง'
                          ? '#F0F9FF'
                          : '#F2F4F7',"dark":""}},[(item.status == 'เปิดใช้งาน')?_c('span',{staticStyle:{"color":"#027a48"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status == 'ปิดใช้งาน')?_c('span',{staticStyle:{"color":"#344054"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status == 'ฉบับร่าง')?_c('span',{staticStyle:{"color":"#026aa2"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()])]}},{key:"item.action",fn:function(ref){
                          var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.ViewNews(item)}}},[_vm._v("mdi-content-copy")]),_c('v-icon',{on:{"click":function($event){return _vm.UpdateNews(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteItem(item)}}},[_vm._v("mdi-delete")])]}},(_vm.isWindowWidthGreaterThan768)?{key:"body",fn:function(props){return [_c('draggable',{attrs:{"list":props.items,"tag":"tbody"},on:{"change":function($event){return _vm.DragItems(props.items)}}},_vm._l((props.items),function(item,index){return _c('tr',{key:index},[(_vm.isWindowWidthGreaterThan768)?_c('td',[_c('v-icon',{staticClass:"page__grab-icon",attrs:{"small":""}},[_vm._v(" mdi-arrow-all ")])],1):_vm._e(),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(item.index))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(item.title_th))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(item.typeCourse))]),_c('td',{staticClass:"text-center"},[_c('v-chip',{attrs:{"color":item.status == 'เปิดใช้งาน'
                                ? '#ECFDF3'
                                : item.status == 'ฉบับร่าง'
                                ? '#F0F9FF'
                                : '#F2F4F7',"dark":""}},[(item.status == 'เปิดใช้งาน')?_c('span',{staticStyle:{"color":"#027a48"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status == 'ปิดใช้งาน')?_c('span',{staticStyle:{"color":"#344054"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status == 'ฉบับร่าง')?_c('span',{staticStyle:{"color":"#026aa2"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()])],1),_c('td',{staticClass:"text-start"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.ViewNews(item)}}},[_vm._v("mdi-content-copy")]),_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.UpdateNews(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteItem(item)}}},[_vm._v("mdi-delete")])],1)],1)])}),0)]}}:null],null,true)})],1)],1)],1)],1),_c('v-tab-item',[_c('v-card',{staticClass:"pa-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-text-field',{attrs:{"hide-details":"","prepend-inner-icon":"mdi-magnify","placeholder":"ค้นหาประกาศสมัครเรียน","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-select',{attrs:{"outlined":"","dense":"","items":[
                    'สถานะทั้งหมด',
                    'เปิดใช้งาน',
                    'ปิดใช้งาน',
                    'ฉบับร่าง' ]},on:{"change":function($event){return _vm.searchSelectStatus(_vm.statusSearch)}},model:{value:(_vm.statusSearch),callback:function ($$v) {_vm.statusSearch=$$v},expression:"statusSearch"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersBrandTh,"items":_vm.items2,"search":_vm.search1,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status == 'เปิดใช้งาน'
                          ? '#ECFDF3'
                          : item.status == 'ฉบับร่าง'
                          ? '#F0F9FF'
                          : '#F2F4F7',"dark":""}},[(item.status == 'เปิดใช้งาน')?_c('span',{staticStyle:{"color":"#027a48"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status == 'ปิดใช้งาน')?_c('span',{staticStyle:{"color":"#344054"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status == 'ฉบับร่าง')?_c('span',{staticStyle:{"color":"#026aa2"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()])]}},{key:"item.action",fn:function(ref){
                          var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.ViewNews(item)}}},[_vm._v("mdi-content-copy")]),_c('v-icon',{on:{"click":function($event){return _vm.UpdateNews(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteItem(item)}}},[_vm._v("mdi-delete")])]}},(_vm.isWindowWidthGreaterThan768)?{key:"body",fn:function(props){return [_c('draggable',{attrs:{"list":props.items,"tag":"tbody"},on:{"change":function($event){return _vm.DragItems1(props.items)}}},_vm._l((props.items),function(item,index){return _c('tr',{key:index},[(_vm.isWindowWidthGreaterThan768)?_c('td',[_c('v-icon',{staticClass:"page__grab-icon",attrs:{"small":""}},[_vm._v(" mdi-arrow-all ")])],1):_vm._e(),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(item.index))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(item.title_th))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(item.typeCourse))]),_c('td',{staticClass:"text-center"},[_c('v-chip',{attrs:{"color":item.status == 'เปิดใช้งาน'
                                ? '#ECFDF3'
                                : item.status == 'ฉบับร่าง'
                                ? '#F0F9FF'
                                : '#F2F4F7',"dark":""}},[(item.status == 'เปิดใช้งาน')?_c('span',{staticStyle:{"color":"#027a48"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status == 'ปิดใช้งาน')?_c('span',{staticStyle:{"color":"#344054"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status == 'ฉบับร่าง')?_c('span',{staticStyle:{"color":"#026aa2"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()])],1),_c('td',{staticClass:"text-start"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.ViewNews(item)}}},[_vm._v("mdi-content-copy")]),_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.UpdateNews(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteItem(item)}}},[_vm._v("mdi-delete")])],1)],1)])}),0)]}}:null],null,true)})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }