<template>
  <div>
    <ManageScholarshipsMaster />
  </div>
</template>
<script>
import ManageScholarshipsMaster from "@/components/ManageScholarshipsMaster/ManageScholarshipsMaster";
export default {
  components: {
    ManageScholarshipsMaster,
  },
  created() {},
};
</script>
